.adjustment_dialog {
  .paper_custom_dialog {
    width: 424px;
    min-width: 424px;
  }

  .dialog_wrapper {
    display: block;
    padding: 23px 28px 24px;
  }

  &__title {
    margin-bottom: 24px;
    font-size: 22px;
    line-height: 26px;
    font-weight: 500;
  }

  &__columns {
    display: flex;
    gap: 32px;
  }

  &__label {
    display: inline-block;
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 16px;
    color: #4a5568;
  }

  &__select {
    min-width: 186px;

    .select_component {
      min-height: 44px;
    }

    .select__control {
      padding-left: 12px;
      padding-right: 12px;
      border-radius: 8px;
      border: 1px solid #cbd5e0;

      &:hover, &:focus-visible {
        border-color: #1c2225 !important;
      }

      .select__single-value {
        font-size: 14px;
        color: #1A202C;
      }
    }
  }

  &__error {
    margin-top: 16px;
    margin-bottom: -16px;
    text-align: center;
    font-size: 14px;
    line-height: 16px;
    color: #ff010b;
  }

  &__btn-group {
    display: flex;
    gap: 16px;
    margin-top: 32px;
  }

  &__btn {
    flex: 1;

    .MuiButton-root {
      max-height: 40px;
    }

    .MuiButton-label {
      font-size: 14px;
    }
  }

  .quantity_input {
    display: flex;
    align-items: center;
    gap: 4px;
    min-height: 44px;

    &__btn {
      width: 24px;
      height: 24px;

      svg path {
        transition: .3s;
      }

      &:hover, &:focus-visible {
        svg path {
          fill: #1a202c;
        }
      }
    }

    &__input {
      box-sizing: border-box;
      text-align: center;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
      width: 32px;
      height: 32px;
      background-color: #ffffff;
      border: 1.5px solid rgba(48, 49, 51, 0.15);
      border-radius: 4px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
      transition: border-color .1s;

      &:hover, &:focus-visible {
        border-color: #1c2225;
      }
    }
  }
}
