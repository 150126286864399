////////////////// pages ////////////////////

body {
    color: #1a202c;
    box-sizing: border-box;
    background: #ffffff;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    user-select: none;
}

.no_search_items {
    font-size: 14px;
    line-height: 21px;
    color: #1a202c;
    padding-bottom: 5px;
    display: inline-block;
}

.page_wrapper {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    flex-flow: column;
    // padding: 0 32px 0 0;
    padding: 0 0 0 18px;
    background: #fafafa;
    height: 100%;
}

.transitioned_block {
    height: 100%;
}

.page {
    height: calc(100vh - 72px);
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    padding-top: 72px;
}

.wrapper {
    height: 100%;
}

.controls_wrapper {
    background: #ffffff;
    box-shadow: 0px 0px 16px rgba(48, 49, 51, 0.12);
    border-radius: 8px;
    min-width: 204px;
    max-width: calc(100vw - 40px);
    height: 294px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    position: absolute;
    z-index: 100000 !important;
    right: 22px;
    bottom: 20px;
    top: 78px;
    padding: 16px 14px;
    box-sizing: border-box;

    &-sales {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .sales {
            font-size: 14px;
            line-height: 16px;
            font-weight: 500;
            color: #1a202c;
        }
        .prices {
            color: #4a5568;
            opacity: 0.5;
            font-size: 12px;
            line-height: 14px;
        }
    }

    &-days {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #4a5568;
        font-size: 12px;
        line-height: 14px;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid #edf0f4;
        > div {
            .digits {
                color: #1a202c;
                font-weight: bold;
                font-size: 17px;
                line-height: 20px;
                margin-right: 3px;
            }
            > p {
                margin-top: 4px;
            }
        }
    }
    > a {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        > img {
            margin-right: 10px;
        }
        text-decoration: none;
        > p {
            color: #4a5568;
            font-size: 13px;
            line-height: 15px;
        }
    }
    .quit {
        display: flex;
        align-items: center;
        > img {
            margin-right: 10px;
        }
        > p {
            color: #4a5568;
            font-size: 13px;
            line-height: 15px;
        }
    }
}

/////////////// hover ///////////////

.good_hover {
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;
    -ms-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s;
    /* &:hover {
        opacity: 0.5;
    } */
}
a,
button {
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    cursor: pointer;
    text-decoration: none;
    background: transparent;
    font-family: "Roboto", sans-serif;
}

.visually-hidden {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
}

@media only screen and (max-width: 1279px) {
    .page {
        padding-top: 72px;
        height: calc(100vh - 72px);
    }
}
