.spinner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    > img {
        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
        animation: spin 2s linear infinite;
    }
}
