.default_dialog_root {
    .paper_custom_dialog {
        border-radius: 6px;
        min-width: 460px;
        width: 460px;
        box-shadow: none;
        @media only screen and (max-height: 300px) {
            height: 100%;
            margin: 0;
            justify-content: flex-start;
            padding: 32px 32px;
            overflow-y: auto;
            border-radius: 0;
            .dialog_wrapper {
                align-items: flex-start;
            }
        }
    }
    .dialog_close_button {
        cursor: pointer;
        position: absolute;
        right: 28px;
        top: 26px;
        background: transparent;
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;
        &:hover {
            transform: scale(1.1);
        }
    }
}

.dialog_wrapper {
    display: flex;
    position: relative;
    background-color: #ffffff;
    overflow: auto;
    form {
        width: 100%;
    }
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }
    &::-webkit-scrollbar-track {
        -webkit-border-radius: 2px;

        background: #edf2f8;
    }
    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 5px;
        border-radius: 5px;
        background: grey;
    }
    &::-webkit-scrollbar-thumb:window-inactive {
        background: grey;
    }
}
