.sidebar_wrapper {
    /* width: 473px;
	min-width: 473px; */
    height: 100%;
    box-shadow: 0px -2px 16px rgba(48, 49, 51, 0.12);
    background: #ffffff;
    overflow-y: auto;

    .logo-wrapper {
        padding: 24px 11px 0 20px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        > img {
            cursor: pointer;
        }
    }

    &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }
    &::-webkit-scrollbar-track {
        -webkit-border-radius: 60px;
        background: #f2f2f3;
    }
    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 60px;
        border-radius: 60px;
        background: rgba(74, 85, 104, 0.25);
    }
    &::-webkit-scrollbar-thumb:window-inactive {
        background: rgba(74, 85, 104, 0.25);
    }
}

.tree-item-content {
    display: flex;
    align-items: center;
    > img {
        width: 24px;
        height: 24px;
        margin-right: 8px;
    }
}

.cat_sidebar {
    max-width: 473px;
    margin-right: 0 !important;
    border-right: 1px solid rgba(203, 213, 224, 0.5);

    overflow: auto;
    height: calc(100vh - 68px);

    &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }
    &::-webkit-scrollbar-track {
        -webkit-border-radius: 60px;
        background: #f2f2f3;
    }
    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 60px;
        border-radius: 60px;
        background: rgba(74, 85, 104, 0.25);
    }
    &::-webkit-scrollbar-thumb:window-inactive {
        background: rgba(74, 85, 104, 0.25);
    }

    &.MuiTreeView-root {
        margin-left: auto;
        background: #ffffff;
    }

    .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label,
    .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:active,
    .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:focus,
    .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover {
        background-color: #fff7f7 !important;
    }
    .MuiTreeItem-root:focus > .MuiTreeItem-content .MuiTreeItem-label {
        background-color: unset !important;
    }

    .MuiTreeItem-content {
        transition: 0.3s;

        &:hover {
            // opacity: 0.7;
        }
    }
}

.cat_item {
    .MuiTypography-body1 {
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: #1a202c;
    }

    .MuiTreeItem-content {
        display: flex;
        flex-direction: row-reverse;
        // min-height: 64px;
        border-bottom: 1px solid rgba(203, 213, 224, 0.5);
    }

    .MuiTreeItem-label {
        margin-left: 20px;
        padding-left: 0;
        padding-top: 16px;
        padding-bottom: 16px;

        &:hover {
            background-color: unset;
        }
        &:focus {
            background-color: unset;
        }
    }

    .MuiTreeItem-iconContainer {
        margin-right: 10px;
        width: 36px;
    }

    &.Mui-expanded {
        // background-color: rgba(255, 1, 11, 0.03);

        & > .MuiTreeItem-content {
            position: relative;

            // &:before {
            //     content: "";
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     width: 4px;
            //     height: 100%;
            //     background: #ff010b;
            // }
        }

        .MuiTreeItem-label {
            //color: #ff010b;
        }
    }

    .MuiTreeItem-group {
        margin-left: 0;
        background-color: #ffffff;

        .MuiTreeItem-label {
            font-size: 14px;
            line-height: 20px;
            color: #4a5568;
        }
    }

    &.required-nodes {
        .tree-item-content {
            color: #ff010b;
            font-weight: 500;
        }
    }
}

.current_cat {
    background-color: #fff7f7;
    position: relative;
    .MuiTreeItem-content:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 4px;
        height: 100% !important;
        background: #ff010b;
    }
    & > div {
        & > .MuiTreeItem-label {
            position: relative;
            //padding-left: 45px;
            // color: rgb(0, 0, 0) !important;

            /* &:before {
				position: absolute;
				content: url("../../assets/image/cat-check.svg");
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				width: 32px;
				height: 32px;
			} */
        }
    }
}

@media only screen and (max-width: 1279px) {
    .current_cat {
        .MuiTreeItem-content:before {
            height: 50px;
        }
    }
    .cat_item {
        .MuiTreeItem-content {
            // min-height: 50px;
        }

        .MuiTypography-body1 {
            font-size: 14px;
            line-height: 20px;
        }
        .MuiTreeItem-iconContainer {
            img {
                background-size: 100% 100%;
            }
        }
    }
    // .cat_item.Mui-expanded > .MuiTreeItem-content:before {
    //     height: 50px;
    // }
}
