.shop_page {
    display: flex;
    flex-flow: initial;
}

.shop_content {
    padding-top: 36px;
    display: flex;
    flex-flow: column;
    width: 100%;
    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
    &::-webkit-scrollbar-track {
        -webkit-border-radius: 2px;
        background: #fafafa;
    }
    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 5px;
        border-radius: 5px;
        background: rgba(74, 85, 104, 0.25);
    }
    &::-webkit-scrollbar-thumb:window-inactive {
        background: rgba(74, 85, 104, 0.25);
    }
    overflow-x: auto;
    max-height: 100%;

    .catalog_product {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
    }
}

.mb40 {
    margin-bottom: 40px;
}

.more-products {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-top: 24px;
    cursor: pointer;
    > span {
        color: #ff010b;
        font-family: "RobotoMedium", sans-serif;
        font-size: 12px;
        line-height: 12px;
        text-transform: uppercase;
    }
}

.product {
    position: relative;
    margin-bottom: 14px;
    min-width: 181px;
    width: 181px;
    background: #ffffff;
    box-shadow: 0px 6px 12px rgba(48, 49, 51, 0.1);
    border-radius: 8px;
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    margin-right: 12px;
    .dots-wrapper {
        // margin-left: 5px;
        cursor: pointer;
    }
    .no-items {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        min-height: 38px;
        display: flex;
        align-items: center;
    }
    .cart-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;
        .cart-added-wrapper,
        .cart-add-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 38px;
            height: 38px;
            background: #f3f5f6;
            box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.03);
            border-radius: 8px;
            &.disabled {
                opacity: 0.3;
            }
        }
        .cart-add-wrapper {
            background: #1cbb32;
            cursor: pointer;
        }
        .cart-no-items-add {
            background: #ff010b;
        }
    }
    .input_add {
        display: flex;
        align-items: center;
        input {
            box-sizing: border-box;
            margin: 0 15px;
            height: 32px;
            padding: 0 4px;
            font-family: "RobotoMedium", sans-serif;
            font-size: 14px;
            text-align: center;
            border: 1.5px solid rgba(48, 49, 51, 0.15);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
            transition: 0.3s;
            width: 40px;
            border-radius: 4px !important;

            &:focus {
                border-color: rgba(48, 49, 51, 0.3);
            }
        }

        .cart-minus,
        .cart-plus {
            cursor: pointer;
            width: 12px;
        }
        .cart-minus {
            padding: 15px 0;
        }
        .cart-plus {
            padding: 10px 0;
        }
    }
    .shops-wrapper {
        background: #ffffff;
        box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.03), 0px 4px 10px rgba(68, 68, 79, 0.08);
        border-radius: 8px;
        padding: 8px;
        margin-top: 6px;
        > p {
            color: #4a5568;
            font-size: 12px;
            line-height: 14px;
            text-align: center;
            margin-bottom: 10px;
        }
        .error-download {
            margin-top: 10px;
        }
        &-content {
            > div {
                display: flex;
                align-items: center;
                margin-bottom: 8px;
                &:last-of-type {
                    margin-bottom: 0px;
                }
                p {
                    color: #4a5568;
                    font-size: 11px;
                    line-height: 14px;
                    &:last-child {
                        white-space: nowrap;
                    }
                }
            }
            .shop-item-name {
                max-width: 73px;
                margin-right: 23px;
            }
        }
    }
    .recent-products {
        min-height: 22px;
        margin-top: 12px;
        display: flex;
        align-items: center;
        &-text {
            color: #4a5568;
            font-size: 12px;
            line-height: 14px;
            margin-right: 6px;
        }
        .whiteplus-wrapper,
        .minus-wrapper {
            cursor: pointer;
            background: #1cbb32;
            border-radius: 50px;
            width: 22px;
            height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .minus-wrapper {
            background: #f3f5f6;
        }
    }
    .art {
        font-family: "Roboto", sans-serif;
        font-size: 12px;
        background: rgba(207, 216, 220, 0.25);
        border-radius: 0px 8px;
        position: absolute;
        top: 0;
        right: 0;
        padding: 7px 12px;
        font-weight: 500;
        letter-spacing: 0.02em;
        color: #1a202c;
    }
    .icon {
        display: flex;
        justify-content: center;
        img {
            margin-bottom: 14px;
            width: 100%;
            height: 100px;
            object-fit: contain;
            max-width: 70px;
            max-height: 70px;
        }
    }
    .name {
        min-height: 40px;
        color: #1a202c;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        // height: 42px;
        overflow: hidden;
        position: relative;
        // min-height: 40px;
        .show {
            position: absolute;
            top: 20px;
            right: 0;
        }
        .hide {
            position: absolute;
            bottom: 0px;
            right: 0;
        }
    }
    .price-wrapper {
        display: flex;
        align-items: center;
    }
    .price_product {
        font-weight: bold;
        font-size: 16px;
        margin-top: 10px;
        &.discount {
            position: relative;
            margin-right: 9px;
            color: rgba(74, 85, 104, 0.5);
            font-size: 12px;
            line-height: 14px;
            font-weight: 400;
            width: max-content;
            &:before {
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 1px;
                background: #ff010b;
                top: calc(50%);
                left: 0;
                transform: translateY(-50%);
            }
        }
    }
    .in_stock {
        padding-top: 10px;
        display: flex;
        align-items: center;
        img {
            margin-right: 9px;
        }
        span {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #1cbb32;
        }
    }
    .pre_order {
        padding-top: 10px;
        display: flex;
        align-items: center;
        img {
            margin-right: 9px;
        }
        span {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #ff010b;
        }
    }

    .info_product_ {
        position: absolute;
        left: 10px;
        top: 10px;
    }
}

.content_header {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    margin-right: 18px;
}

.title_page {
    margin-right: 16px;
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;
}

.catalog_wrapper {
    min-width: 327px;
    margin-right: 28px;
}

.catalog_product {
    z-index: 0;
}

@media only screen and (max-width: 1279px) {
    .catalog_wrapper {
        //margin-left:-32px;
        min-width: 215px;
        width: 215px;
        margin-right: 15px;
    }
    .shop_content {
        padding-top: 20px;
        .catalog_product {
            .product {
                // width: calc(33% - 16px);
                min-width: auto;
            }
        }
    }
}

.card-discount {
    position: relative;
    > span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        font-size: 14px;
    }
}
