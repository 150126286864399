.rating_wrapper {
  display: flex;
  align-items: center;
  & > span {
    margin-left: 8px;
    color: rgba(245, 166, 35, 0.2);
    font-size: 14px;
    line-height: 17px;
    &[class^="react-stars"]:before {
      z-index: 0;
    }
  }
  span {
    &[class^="react-stars"]:before {
      z-index: 0;
    }
  }
}