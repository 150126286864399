.search_page{
  padding: 32px;
  box-sizing: border-box;
  .search_info{
    width: 100%;
    box-sizing: border-box;
    .wrapper_scroll{
      //overflow-x: auto;
      //align-items: flex-start;
      max-height: 575px;
      &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
      }
      &::-webkit-scrollbar-track {
        -webkit-border-radius: 2px;
        background: #fafafa;
      }
      &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 5px;
        border-radius: 5px;
        background: rgba(74, 85, 104, 0.25);
      }
      &::-webkit-scrollbar-thumb:window-inactive {
        background: rgba(74, 85, 104, 0.25);
      }
      .info{
        padding-top: 16px;
        display: flex;
        flex-flow: column;
        .cat_img {
          width: 140px;
          height: 90px;
          object-fit: cover;
        }
        p{
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          padding-bottom: 6px;
          display: flex;
        }
        >div{
          display: flex;
          flex-wrap: wrap;
          span{
            margin-right: 15px;
            display: flex;
            flex-flow: column;

            font-size: 14px;
            line-height: 21px;
            padding-bottom: 14px;
            width: 140px;
            max-width: 140px;
            p{
              font-weight: 500;
              font-size: 16px;
              line-height: 25px;
              display: flex;
              justify-content: center;
              img{
                background-size: 100% 100%;
                max-width: 140px;
              }
            }
          }
        }
      }
      .info:last-of-type{
        span:last-of-type{
          padding: 0;
        }
      }
      .title_search{
        padding-bottom: 18px;
        font-weight: 500;
        font-size: 24px;
        display: flex;
        line-height: 28px;
      }
      .product_search{
        display: flex;
        align-items: flex-start;
        flex-flow: column;
        .product_info{
          padding-bottom: 24px;
          position: relative;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .wrapper_info{
            display: flex;
            align-items: center;
            .img{
              margin-right: 24px;
              img{
                width: 80px;
                height: 80px;
                background-size: 100% 100%;
              }
            }
            .prod_info{
              .name{
                font-size: 16px;
                max-width: 420px;
                line-height: 21px;
                display: flex;
                padding-bottom: 6px;
                align-items: center;
                position: relative;
                padding-right: 60px;
                p {
                  font-weight: 500;

                }
                span {
                  position: absolute;
                  top: calc(50% - 3px);
                  right: 15px;
                  transform: translateY(-50%);
                  margin-left: 12px;
                  img{
                    background-size: 100% 100%;
                    width: 32px;
                    height: 32px;
                  }
                }
              }
              .wrapper_info{
                display: flex;
                align-items: center;
                .price {
                  font-weight: bold;
                  font-size: 24px;
                  line-height: 28px;
                }
                .stock {
                  color: #ff010b;
                  display: flex;
                  align-items: flex-end;
                  p {
                    margin-left: 16px;
                    font-size: 16px;
                    line-height: 19px;
                    color: rgba(74, 85, 104, 0.5);
                    padding-bottom: 3px;
                    position: relative;
                    &:before {
                      content: "";
                      position: absolute;
                      left: 0;
                      top: calc(50% - 0.5px);
                      height: 1px;
                      background: #ff010b;
                      width: 100%;
                    }
                  }
                }
                .art {
                  margin-right: 18px;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 14px;
                  padding: 8px 16px;
                  background: rgba(203, 213, 224, 0.25);
                  border-radius: 8px;
                }
              }
            }
          }
          .info_yes {
            position: absolute;
            right: 45px;
            top: 0;
            background: rgba(28, 187, 50, 0.08);
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #1cbb32;
            width: 138px;
            height: 32px;
            img {
              margin-right: 8px;
            }
          }
          .info_no {
            position: absolute;
            right: 45px;
            top: 0;
            background: rgba(255, 1, 11, 0.08);
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #FF010B;
            width: 138px;
            height: 32px;
            img{
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1279px) {
  .search_page {
    height: 100%;
    .search_info {
      height: 100%;
      .wrapper_scroll {
        height: 100%;
        max-height: 100%;
        .product_search{
          .product_info{
            .wrapper_info{
              .img{
                margin-right: 15px;
                img{
                  width: 60px;
                  height: 60px;
                }
              }
              .prod_info{
                .name{
                  font-size: 15px;
                  line-height: 18px;
                }
                .wrapper_info{
                  .art{
                    margin-right: 15px;
                    font-size: 13px;
                    line-height: 13px;
                    padding: 6px 14px;
                  }
                  .price{
                    font-size: 20px;
                    line-height: 23px;
                  }
                }
              }
            }
            .info_yes{
              font-size: 13px;
              line-height: 14px;
              width: 125px;
              height: 30px;
            }
            .info_no{
              font-size: 13px;
              line-height: 14px;
              width: 125px;
              height: 30px;
            }
          }
        }
        .info{
          >p{
            padding-bottom: 10px;
          }
          >div{
            span{
              p{
                font-size: 14px;
                padding-top: 4px;
                line-height: 18px;
              }
            }
          }
          .cat_img{
            background-size: 100% 100%;
            width: 120px;
            height: 75px;
          }
        }
      }
    }
  }
}
