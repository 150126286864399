.select_wrapper {
    width: 100%;
}

.select_component {
    height: 35px;
    width: 100%;
    &.select--is-disabled {
        cursor: not-allowed !important;
    }
    & ~ .date_wrapper {
        margin-left: 20px;
    }
    .select__control {
        min-height: 35px;
        box-sizing: border-box;
        border-radius: 0px;
        padding: 0 ;
        height: 100%;
        background-color: #ffffff;
        color: #1E282C !important;
        font-family: "Roboto", sans-serif !important;
        font-size: 16px;
        border: 1px solid transparent;
        border-bottom: 1px solid #cfd8dc;
        &:hover {
            cursor: pointer;
            border: 1px solid transparent!important;
            border-bottom: 1px solid #1C2225!important;
        }
        .select__placeholder {
            color: #4A5568;
            font-family: "Roboto",sans-serif !important;
            font-size: 16px;
            line-height: 19px;
            padding-left: 0px;
            margin: 0 !important;
        }

        .select__value-container {
            padding: 0 !important;
        }

        .select__single-value {
            color: #383838;
            margin: 0 !important;
            font-size: 16px;
            font-family: "Roboto",sans-serif !important;
            text-transform: capitalize;
            padding-left: 0px !important;
        }
        .select__indicators {
            > div {
                padding: 0 !important;
            }
        }
        .select__indicator-separator {
            background: none;
        }
        .select_indicator {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                transition: all 0.3s linear;
            }
        }
    }
    .select__control--is-focused {
        box-shadow: none;
        //border: 1px solid #020018!important;
        &:hover {
            //border: 1px solid #020018!important;
        }
        .indicator_active {
            img {
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                -o-transform: rotate(180deg);
                transform: rotate(180deg);
            }
        }
    }
    .select__menu {
        z-index: 9999;
        border-radius: 4px !important;
        background-color: #ffffff;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
        border: none !important;
        margin-top: 8px !important;
        .select__menu-list {
            font-size: 14px;
            max-height: 116px;
            font-family: "Roboto",sans-serif !important;
            color: #020018;
            background: transparent;
            .select__option {
                padding: 10px;
                background: transparent;
                color: #020018;
                cursor: pointer;
                transition: all 0.3s linear;

            }
            .select__option--is-selected {
                color: #020018;
                font-family: "Roboto",sans-serif !important;
                background-color: #ffffff;
                text-transform: capitalize;
            }
            .status_select {
                padding: 0 12px;
                display: flex;
                align-items: center;
                background: transparent;
                &:hover {
                    background: transparent;
                }
            }
            &::-webkit-scrollbar {
                width: 5px;
                height: 5px;
            }
            &::-webkit-scrollbar-track {
                -webkit-border-radius: 2px;
                background: #f2f2f3;
            }
            &::-webkit-scrollbar-thumb {
                -webkit-border-radius: 5px;
                border-radius: 5px;
                background: rgba(74, 85, 104, 0.25);
            }
            &::-webkit-scrollbar-thumb:window-inactive {
                background: rgba(74, 85, 104, 0.25);
            }
        }
    }
    input {
        height: unset;
    }
}

@media only screen and (max-width: 1279px) {
    .select_compone1nt {
        height: 30px;
        .select__control{
            min-height: 30px;
        }
    }
    .select_component .select__control .select__placeholder{
        font-size: 15px;
    }
    .select_component .select__control .select__single-value{
        font-size: 15px;
    }
}

