.advantages-page {
    .title_page {
        padding-bottom: 20px;
    }

    .advantages-content {
        display: flex;
        flex-wrap: wrap;

        .advantage-block {
            @media only screen and (max-width: 1199.9px) {
                width: calc(33.333% - 55px);
            }
            @media only screen and (min-width: 1200px) {
                width: calc(25% - 55px);
            }
            @media only screen and (min-width: 1440px) {
                width: calc(20% - 55px);
            }
            padding: 20px 18px 32px;
            margin-right: 15px;
            margin-bottom: 15px;
            background: #ffffff;
            box-shadow: 0px 6px 12px rgba(48, 49, 51, 0.1);
            border-radius: 8px;

            & > svg {
                margin-bottom: 16px;
            }

            & > .advantage-text {
                font-weight: 500;
                font-size: 14px;
                color: #2d3d45;
            }
        }
    }
}
