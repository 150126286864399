.client_page {
  background: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-flow: column;
  &.registration {
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    flex-direction: column;
    overflow-y: auto;
    .select_component .select__control .select__single-value {
      text-transform: none;
    }
    & > div {
      //width: 100%;
      margin: 50px auto 0;
      &:first-child {
        & > a {
          justify-content: flex-start;
        }
      }
    }
  }
  .back_block {
    margin-bottom: 33px;
    width: 460px;
  }
  .client_content {
    width: 460px;
    //max-width: 460px;
    background: #ffffff;
    box-shadow: 0px 8px 16px rgba(48, 49, 51, 0.16);
    border-radius: 8px;
    padding: 36px 40px;
    box-sizing: border-box;

    &.waiting {
      position: relative;
      & > *:not(.loader) {
        opacity: 0.3;
      }
      .loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        display: flex;
        justify-content: center;
        align-items: center;
        .MuiCircularProgress-root {
          color: #ff4048;
        }
      }
    }
  }
  .title {
    padding-bottom: 36px;
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
  }
  .descriptions {
    padding-bottom: 36px;
    margin-top: -20px;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #4a5568;
  }
  form {
    .btn_green {
      width: 100%;
      height: 52px;
      background: #1cbb32;
      box-shadow: 0px 6px 12px rgba(28, 187, 50, 0.08);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      cursor: pointer;
    }
    .back_btn {
      margin-top: 18px;
    }
  }
  .client_info {
    &.client_content {
      padding: 0;
      & > div {
        padding: 0 20px;
        &:not(:last-child) {
          border-bottom: 1px solid #e1ecf1;
          margin-bottom: 14px;
        }
        &:first-child {
          padding-top: 20px;
        }
        &:last-child {
          padding-bottom: 20px;
        }
      }
      .title {
        padding-bottom: 30px;
        font-size: 25px;
        line-height: 30px;
      }
    }
    .name {
      padding-bottom: 6px;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #1e282c;
    }
    .phone {
      font-size: 14px;
      line-height: 20px;
      color: #4a5568;
      padding-bottom: 16px;
    }
    .mail {
      padding-bottom: 6px;
      font-size: 14px;
      line-height: 16px;
      color: #4a5568;
    }
    .mail_info {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #2d3d45;
      padding-bottom: 12px;
    }
    .discount_info_wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding-bottom: 16px !important;
      img {
        margin-right: 12px;
      }
      & > div {
        display: flex;
        align-items: baseline;
        .discount {
          font-size: 14px;
          line-height: 16px;
          color: #4a5568;
          padding-bottom: 0;
        }
        .discount_info {
          font-weight: bold;
          font-size: 18px;
          color: #1cbb32;
          margin-left: 5px;
        }
      }
    }
    .delivery_info {
      &.wrapper,
      &.form {
        padding-bottom: 14px;
      }
      &.wrapper {
        margin-bottom: 0 !important;
        height: auto;
        &.need-delivery {
          border-bottom: 1px solid #fff;
        }
        & > label {
          margin: 0;
          & > span {
            padding: 0 10px 0 0;
            & + span {
              font-weight: normal;
              font-size: 14px;
              line-height: 24px;
              color: #4a5568;
            }
          }
        }
      }
      &.form {
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        background-color: rgba(203, 213, 224, 0.25);
        box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.05);
        border: none !important;
        height: 0;
        overflow: hidden;
        transition: height 250ms;
        & > div {
          &:first-child {
            margin-top: 8px;
          }
          & > label {
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            color: #4a5568;
            &.Mui-focused {
              font-weight: normal;
              font-size: 12px;
              line-height: 16px;
              color: #4a5568;
            }
            &.MuiInputLabel-shrink {
              transform: translate(0, 6px) scale(0.75);
              font-size: 12px;
            }
          }
        }
        &.need-delivery {
          border-bottom: 1px solid rgba(203, 213, 224, 0.25);
          height: 125px;
        }
      }
    }
    .sum_info {
      padding-bottom: 14px !important;
      &:not(.general) {
        border-bottom-style: dashed !important;
        border-bottom-width: 2px !important;
      }
      p {
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #4a5568;
        span {
          font-weight: 500;
          color: #1e282c;
        }
      }
      &.general {
        p > span {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
    .storage_error {
      color: red;
      display: inline-block;
      margin-bottom: 12px;
      font-size: 12px;
    }
    button.end_session {
      width: 100%;
      height: 40px;
      background: #ff010b;
      box-shadow: 0px 6px 12px rgba(28, 187, 50, 0.08);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
      position: relative;
      & > .MuiCircularProgress-colorSecondary {
        color: #ffffff;
        position: absolute;
        left: 5px;
        top: 12px;
        height: 15px !important;
        width: 15px !important;
      }
    }
  }
  &.with_cart {
    flex-direction: row;
    align-items: flex-start;
    overflow-y: auto;
    padding: 20px 0;
    .client_content.client_info {
      &:not(.cart) {
        width: 35%;
        margin: 0 20px;
        height: 100%;
        overflow-y: auto;
        position: sticky;
        top: 0;
        & + div {
          width: 65%;
          margin: 0 20px 0 0;
        }
      }
      &.cart {
        width: 100%;
        & > div {
          border-bottom: none;
          .name {
            padding: 0;
          }
          .clear_cart {
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: #fe1a23;
          }
          &:first-child {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
        &:not(:last-child) {
          margin-bottom: 20px;
        }
        &.empty {
          p {
            margin-left: 24px;
            padding-bottom: 24px;
            opacity: 0.7;
            font-size: 12px;
          }
        }
        &:not(.empty) {
          & > div {
            &.cart-item {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              padding-bottom: 0;
              & > .container {
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: flex-start;
                padding: 15px 0;
                &:not(:last-child) {
                  border-bottom: 1px solid #e1ecf1;
                }
                & > .image-wrapper {
                  width: 64px;
                  padding-right: 16px;
                  img {
                    width: 64px;
                    height: 64px;
                    object-fit: contain;
                  }
                }
                & .info {
                  &-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    width: calc(100% - 80px);
                  }
                  &.header {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    align-items: flex-start;
                    & > .heading {
                      font-weight: normal;
                      font-size: 14px;
                      line-height: 20px;
                      color: #1a202c;
                    }
                    & > .delete {
                      margin: -4px 0 0 15px;
                    }
                  }
                  &.body {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    margin: 12px 0 0;
                    .input_add {
                      display: flex;
                      align-items: center;
                      input {
                        box-sizing: border-box;
                        margin: 0 11px;
                        height: 32px;
                        padding: 0 4px;
                        font-family: "RobotoMedium", sans-serif;
                        font-size: 14px;
                        text-align: center;
                        border: 1.5px solid rgba(48, 49, 51, 0.15);
                        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
                        transition: 0.3s;
                        width: 50px;
                        border-radius: 4px !important;
                        &:focus {
                          border-color: rgba(48, 49, 51, 0.3);
                        }
                      }

                      .cart-minus,
                      .cart-plus {
                        cursor: pointer;
                        width: 12px;
                      }
                      .cart-minus {
                        padding: 15px 0;
                      }
                      .cart-plus {
                        padding: 10px 0;
                      }
                      .edit_btn {
                        margin-left: 11px;
                      }
                    }
                    .price {
                      display: flex;
                      align-items: center;
                      span {
                        font-weight: bold;
                        font-size: 16px;
                        line-height: 24px;
                        color: #1a202c;
                        &.discount {
                          font-weight: normal;
                          font-size: 12px;
                          line-height: 20px;
                          color: rgba(74, 85, 104, 0.5);
                          position: relative;
                          margin-right: 20px;
                          &::before {
                            content: "";
                            display: block;
                            position: absolute;
                            top: 50%;
                            left: -5px;
                            width: calc(100% + 10px);
                            height: 1px;
                            transform: translateY(-50%);
                            background-color: #ff010b;
                          }
                        }
                      }
                    }
                  }
                  &.footer {
                    display: flex;
                    width: 100%;
                    justify-content: flex-end;
                    margin-top: 10px;
                    img {
                      width: 16px;
                      height: 16px;
                      margin-right: 6px;
                    }
                    span {
                      font-weight: normal;
                      font-size: 12px;
                      line-height: 20px;
                      color: #4a5568;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .no_items{
    margin-left: 24px;
    padding-bottom: 24px;
    opacity: 0.7;
    font-size: 12px;
  }
}
.dialog_client {
  justify-content: center;
  padding: 60px 40px 3px;
  width: 100%;
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  > img {
    margin: 0 auto 32px;
    max-width: 200px;
  }
  > div {
    font-weight: 500;
    font-size: 28px;
    color: #1a202c;
    text-align: center;
    margin-bottom: 29px;
  }
  &.quantity {
    & > div {
      &.modal_title {
        font-size: 18px;
      }
      &.body {
        input {
          box-sizing: border-box;
          margin: 0 11px;
          height: 32px;
          padding: 0 4px;
          font-family: "RobotoMedium", sans-serif;
          font-size: 14px;
          text-align: center;
          border: 1.5px solid rgba(48, 49, 51, 0.15);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
          transition: 0.3s;
          width: 50px;
          border-radius: 4px !important;
          &:focus {
            border-color: rgba(48, 49, 51, 0.3);
          }
        }
      }
      &.footer {
        font-size: 14px;
        img {
          width: 16px;
          height: 16px;
          margin-right: 6px;
        }
        span {
          font-weight: normal;
          font-size: 12px;
          line-height: 20px;
          color: #4a5568;
        }
      }
      &.button_container {
        .default_button_wrapper {
          width: 100%;
        }
      }
    }
  }
  &.are_you_sure {
    & > p {
      margin-bottom: 20px;
    }
  }
}

.registration-progress {
  &.Mui-disabled {
    &:hover {
      background-color: #1cbb32 !important;
    }
  }
  .MuiCircularProgress-colorSecondary {
    color: #ffffff;
    position: absolute;
    left: 20px;
    top: 6px;
  }
}

.advertising_channel {
  margin-bottom: 24px;
}

.change_discount_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  input {
    background: #ffffff;
    box-shadow: 0px -1px 2px rgba(48, 49, 51, 0.04), 0px 8px 16px rgba(48, 49, 51, 0.16);
    border-radius: 8px;
    height: 44px;
    width: 56px;
    line-height: 44px;
    text-align: center;
    margin: auto 20px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 20px;
  }
}

@media only screen and (max-width: 1279px) {
  .client_page {
    padding: 20px 0;
    box-sizing: border-box;
    height: 100%;
  }
  .dialog_client {
    padding: 40px 30px 0;
    > div {
      font-size: 25px;
    }
    > img {
      margin-bottom: 25px;
    }
  }
}
