//@font-face {
//    font-family: "MontserratRegular";
//    src: url("../assets/fonts/Montserrat-Regular.ttf") format("truetype");
//}

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap&subset=cyrillic-ext');

@import "./reset";
@import "./base";
@import "./animations";
