.header_wrapper {
    background: #ffffff;
    box-shadow: 0px 8px 16px rgba(48, 49, 51, 0.08);
    z-index: 999;
    height: 72px;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    .sidebar-wrapper {
        position: absolute;
        z-index: 100000;
        left: 0;
        top: 0;
        width: 244px;
        display: none;
        &.open {
            display: block;
        }
    }
    .menu {
        display: flex;
        .avatar {
            margin-right: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 52px;
                height: 52px;
                background-size: 100% 100%;
                border-radius: 50%;
            }
        }
        .menu_info {
            display: flex;
            flex-flow: column;
            justify-content: center;
            span {
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                display: flex;
                padding-bottom: 4px;
            }
            p {
                font-size: 14px;
                line-height: 16px;
                opacity: 0.75;
            }
        }
        & + .logout-backdrop {
            display: flex;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.3);
            z-index: 999;
        }
    }
    .head_block {
        display: flex;
        align-items: center;
        width: 100%;
        .burger-img {
            margin-right: 14px;
            cursor: pointer;
        }
        .logo {
            margin-right: 26px;
        }
        .search_wrapper {
            display: flex;
            align-items: center;
            position: relative;
            width: 100%;
            > span {
                position: absolute;
                top: calc(50% - 9.5px);
                left: 22px;
                z-index: 3;
                &.error_text {
                    position: absolute;
                    top: 100%;
                    left: 0;
                }
            }
            img {
                position: relative;
                z-index: 1;
            }
            input {
                padding: 0 20px 0 52px;
                height: 52px;
                border: 1px solid #cfd8e2;
                box-sizing: border-box;
                color: #4a5568;
                font-family: "Roboto", sans-serif;
                border-radius: 8px;
                font-size: 16px;
                width: 100%;
                line-height: 19px;
                z-index: 2;
                &::placeholder {
                    opacity: 0.6;
                }
            }
            button {
                width: 120px;
                height: 52px;
                background: #1cbb32;
                box-shadow: 0px 6px 12px rgba(28, 187, 50, 0.08);
                border-radius: 0px 8px 8px 0px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #ffffff;
                font-weight: 500;
                font-size: 18px;
                line-height: 21px;
                z-index: 2;
                cursor: pointer;
            }
            .search_backdrop {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                //background-color: rgba(48, 49, 51, 0.16);
                z-index: 2;
                .search_info {
                    width: 600px;
                    top: 78px;
                    max-height: 617px;
                    z-index: 9999;
                    position: absolute;
                    left: 325px;
                    background: #ffffff;
                    box-shadow: -2px 0px 6px rgba(48, 49, 51, 0.16), 0px 8px 16px rgba(48, 49, 51, 0.12);
                    border-radius: 8px;
                    padding: 18px 24px;
                    box-sizing: border-box;

                    > button {
                        margin-top: 20px;
                        width: 100%;
                        height: 47px;
                        background: #1cbb32;
                        box-shadow: 0px 6px 12px rgba(28, 187, 50, 0.08);
                        border-radius: 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #ffffff;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 21px;
                        cursor: pointer;
                    }
                    .wrapper_scroll {
                        overflow-x: auto;
                        //align-items: flex-start;
                        max-height: 514px;
                        &::-webkit-scrollbar {
                            width: 10px;
                            height: 10px;
                        }
                        &::-webkit-scrollbar-track {
                            -webkit-border-radius: 2px;
                            background: #fafafa;
                        }
                        &::-webkit-scrollbar-thumb {
                            -webkit-border-radius: 5px;
                            border-radius: 5px;
                            background: rgba(74, 85, 104, 0.25);
                        }
                        &::-webkit-scrollbar-thumb:window-inactive {
                            background: rgba(74, 85, 104, 0.25);
                        }
                        .info {
                            border-top: 1px solid rgba(203, 213, 224, 0.5);
                            padding-top: 16px;
                            display: flex;
                            flex-flow: column;
                            p {
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 19px;
                                padding-bottom: 14px;
                                display: flex;
                            }
                            span {
                                font-size: 14px;
                                line-height: 21px;
                                padding-bottom: 14px;
                                display: flex;
                            }
                        }
                        .info:last-of-type {
                            span:last-of-type {
                                padding: 0;
                            }
                        }
                        .title_search {
                            padding-bottom: 14px;
                            font-weight: 500;
                            font-size: 16px;
                            display: flex;
                            line-height: 19px;
                        }
                        .product_search {
                            > div {
                                padding-bottom: 14px;
                                display: flex;
                                align-items: center;
                                .img {
                                    margin-right: 16px;
                                    img {
                                        width: 52px;
                                        height: 52px;
                                        background-size: 100% 100%;
                                    }
                                }
                                .prod_info {
                                    .name {
                                        font-size: 14px;
                                        line-height: 21px;
                                        display: flex;
                                        padding-bottom: 6px;
                                    }
                                    .wrapper_info {
                                        display: flex;
                                        align-items: center;
                                        .price {
                                            font-weight: bold;
                                            font-size: 16px;
                                            line-height: 19px;
                                            &.stock {
                                                color: #ff010b;
                                                display: flex;
                                                align-items: flex-end;
                                                p {
                                                    margin-left: 16px;
                                                    font-size: 16px;
                                                    line-height: 19px;
                                                    color: rgba(74, 85, 104, 0.5);
                                                    position: relative;
                                                    &:before {
                                                        content: "";
                                                        position: absolute;
                                                        left: 0;
                                                        top: calc(50% - 0.5px);
                                                        height: 1px;
                                                        background: #ff010b;
                                                        width: 100%;
                                                    }
                                                }
                                            }
                                        }
                                        .art {
                                            margin-right: 18px;
                                            font-weight: 500;
                                            font-size: 12px;
                                            line-height: 14px;
                                            padding: 6px 14px;
                                            background: rgba(203, 213, 224, 0.25);
                                            border-radius: 8px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.panel_info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 30px;
    a {
        display: flex;
        background: transparent;
        width: 40px;
        opacity: 0.75;
        justify-content: center;
        align-items: center;
        .red {
            display: none;
        }
        svg {
            width: 24px;
            height: 24px;
            path {
                fill: #1a202c;
            }
        }
    }
    a:nth-child(2) {
        margin: 0 10px;
    }
    .active {
        span {
            color: #ff010b;
        }
        .grey {
            display: none;
        }
        .red {
            display: flex;
        }
        svg {
            width: 24px;
            height: 24px;
            path {
                fill: #ff010b;
            }
        }
    }
}

.header-cart {
    box-sizing: border-box;
    height: 100%;
    display: flex;
    background: #f3f5f6;
    align-items: center;
    margin-left: 12px;
    padding: 20px 10px 22px 14px;
    min-width: 140px;
    &-img {
        margin-right: 12px;
        position: relative;
        > div {
            position: absolute;
            top: -7px;
            right: -7px;
            color: #ffffff;
            background: rgba(28, 187, 50, 0.7);
            min-width: 12px;
            height: 18px;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0.95;
            font-weight: 500;
            font-size: 11px;
            line-height: 11px;
            padding: 0px 3px;
        }
        /* > div {
            position: absolute;
            top: -7px;
            right: -7px;
            color: #ffffff;
            background: #1cbb32;
            width: 18px;
            height: 18px;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0.95;
            font-weight: 500;
            font-size: 11px;
            line-height: 11px;
        } */
    }
    &-other {
        .total {
            font-size: 12px;
            line-height: 14px;
            opacity: 0.75;
            color: #2d3d45;
        }
        .price {
            font-weight: 500;
            font-size: 13px;
            line-height: 15px;
            color: #1e282c;
        }
    }
}

@media only screen and (max-width: 1279px) {
    .panel_info {
        margin-left: 0;
    }
    .header_wrapper {
        height: 72px;
        .menu {
            justify-content: flex-end;
            min-width: 170px;
            .avatar {
                img {
                    background-size: 100% 100%;
                    width: 32px;
                    height: 32px;
                }
            }
            .menu_info {
                span {
                    font-size: 13px;
                    line-height: 15px;
                    padding-bottom: 3px;
                }
                p {
                    font-size: 12px;
                    line-height: 14px;
                }
            }
        }
        .head_block {
            .logo {
                a {
                    img {
                        background-size: 100% 100%;
                        width: 100px;
                    }
                }
            }
            .search_wrapper {
                margin-right: 20px;
                justify-content: space-between;
                > input {
                    height: 45px;
                    border-radius: 8px;
                    transition: all 0.3s linear;
                    background: transparent;
                    //color: transparent;
                    width: 100%;
                    padding: 0 15px 0 40px;

                    &::placeholder {
                        //color: transparent;
                    }
                    &:focus {
                        color: #4a5568;
                    }
                }
                > span {
                    z-index: -1;
                    left: 15px;
                    top: calc(50% - 10px);
                    display: flex;
                    img {
                        background-size: 100% 100%;
                        width: 20px;
                        height: 20px;
                    }
                }
                > button {
                    display: none;
                    width: 100px;
                    height: 45px;
                    font-size: 15px;
                    line-height: 17px;
                }
                .search_backdrop {
                    .search_info {
                        left: 215px;
                        max-height: 500px;
                        top: 55px;
                        .wrapper_scroll {
                            max-height: 300px;
                        }
                    }
                }
            }
        }
    }
}
.button_container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    & > span {
        width: 100%;
        margin-top: 15px;
        color: red;
        font-size: 12px;
        font-weight: 400;
        text-align: left;
    }
    & > div {
        width: 45%;
        button {
            position: relative;
            div {
                &.MuiCircularProgress-colorSecondary {
                    color: #ffffff;
                    position: absolute;
                    left: 30px;
                    top: 14px;
                    height: 20px !important;
                    width: 20px !important;
                }
            }
            &.MuiButton-contained {
                box-shadow: none;
                &.disline {
                    background-color: #fff;
                    color: #4a5568;
                    border-width: 2px;
                }
                &.Mui-disabled {
                    &:hover {
                        background-color: #1cbb32;
                        opacity: 0.5;
                    }
                }
            }
        }
    }
}
.custom_dialog{
    .paper_custom_dialog{
        width: 900px;
        max-width: 900px;
    }
    .total_all{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 20px;
        font-size: 20px;
    }
    .dialog_wrapper{
        position: relative;
        flex-direction: column;
        align-items: flex-end;
    }
    .dialog_close_button{
        position: sticky;
        right: 10px;
        left: auto;
        top: 10px;
        margin-top: -20px;
    }
    .dialog_cart{
        box-sizing: border-box;
        width: 100%;
        background: #fafafa;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        flex-flow: column;
        padding: 32px;
        .client_content {
            width: 460px;
            //max-width: 460px;
            background: #ffffff;
            box-shadow: 0px 8px 16px rgba(48, 49, 51, 0.16);
            border-radius: 8px;
            padding: 36px 40px;
            box-sizing: border-box;

            &.waiting {
                position: relative;
                & > *:not(.loader) {
                    opacity: 0.3;
                }
                .loader {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 999;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .MuiCircularProgress-root {
                        color: #ff4048;
                    }
                }
            }
        }
        .title {
            padding-bottom: 36px;
            font-weight: 500;
            font-size: 28px;
            line-height: 36px;
            text-align: center;
        }
        .descriptions {
            padding-bottom: 36px;
            margin-top: -20px;
            font-size: 18px;
            line-height: 27px;
            text-align: center;
            color: #4a5568;
        }
        .with_cart {
            align-items: flex-start;
            flex-direction: column;

            overflow-y: auto;
            padding:0 0 20px 0;
            width: 100%;
            .client_content.client_info {

                width: 100%;

                .cart {
                    width: 100%;

                    & > div {
                        border-bottom: none;
                        .name {
                            padding: 0;
                        }
                        .clear_cart {
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 20px;
                            color: #fe1a23;
                        }
                        &:first-child {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        }
                    }
                    :not(:last-child) {
                        margin-bottom: 20px;
                    }
                    .empty {
                        p {
                            margin-left: 24px;
                            padding-bottom: 24px;
                            opacity: 0.7;
                            font-size: 12px;
                        }
                    }
                }
                .cart:not(.empty) {
                    & > div {
                        &.cart-item {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            padding-bottom: 0;
                            & > .container {
                                width: 100%;
                                display: flex;
                                flex-direction: row;
                                flex-wrap: nowrap;
                                justify-content: space-between;
                                align-items: flex-start;
                                padding: 15px 0;
                                &:not(:last-child) {
                                    border-bottom: 1px solid #e1ecf1;
                                }
                                & > .image-wrapper {
                                    width: 64px;
                                    padding-right: 16px;
                                    img {
                                        width: 64px;
                                        height: 64px;
                                        object-fit: contain;
                                    }
                                }
                                & .info {
                                    &-wrapper {
                                        display: flex;
                                        flex-wrap: wrap;
                                        width: calc(100% - 80px);
                                    }
                                    &.header {
                                        width: 100%;
                                        display: flex;
                                        flex-direction: row;
                                        flex-wrap: nowrap;
                                        justify-content: space-between;
                                        align-items: flex-start;
                                        & > .heading {
                                            font-weight: normal;
                                            font-size: 14px;
                                            line-height: 20px;
                                            color: #1a202c;
                                        }
                                        & > .delete {
                                            margin: -4px 0 0 15px;
                                        }
                                    }
                                    &.body {
                                        width: 100%;
                                        display: flex;
                                        justify-content: space-between;
                                        margin: 12px 0 0;
                                        .input_add {
                                            display: flex;
                                            align-items: center;
                                            input {
                                                box-sizing: border-box;
                                                margin: 0 11px;
                                                height: 32px;
                                                padding: 0 4px;
                                                font-family: "RobotoMedium", sans-serif;
                                                font-size: 14px;
                                                text-align: center;
                                                border: 1.5px solid rgba(48, 49, 51, 0.15);
                                                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
                                                transition: 0.3s;
                                                width: 50px;
                                                border-radius: 4px !important;
                                                &:focus {
                                                    border-color: rgba(48, 49, 51, 0.3);
                                                }
                                            }

                                            .cart-minus,
                                            .cart-plus {
                                                cursor: pointer;
                                                width: 12px;
                                            }
                                            .cart-minus {
                                                padding: 15px 0;
                                            }
                                            .cart-plus {
                                                padding: 10px 0;
                                            }
                                            .edit_btn {
                                                margin-left: 11px;
                                            }
                                        }
                                        .price {
                                            display: flex;
                                            align-items: center;
                                            span {
                                                font-weight: bold;
                                                font-size: 16px;
                                                line-height: 24px;
                                                color: #1a202c;
                                                &.discount {
                                                    font-weight: normal;
                                                    font-size: 12px;
                                                    line-height: 20px;
                                                    color: rgba(74, 85, 104, 0.5);
                                                    position: relative;
                                                    margin-right: 20px;
                                                    &::before {
                                                        content: "";
                                                        display: block;
                                                        position: absolute;
                                                        top: 50%;
                                                        left: -5px;
                                                        width: calc(100% + 10px);
                                                        height: 1px;
                                                        transform: translateY(-50%);
                                                        background-color: #ff010b;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    &.footer {
                                        display: flex;
                                        width: 100%;
                                        justify-content: flex-end;
                                        margin-top: 10px;
                                        img {
                                            width: 16px;
                                            height: 16px;
                                            margin-right: 6px;
                                        }
                                        span {
                                            font-weight: normal;
                                            font-size: 12px;
                                            line-height: 20px;
                                            color: #4a5568;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

            }
        }
        .cart_add{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 12px;
            color: #FFFFFF;
            border-radius: 8px;
            background: #1cbb32;
        }

    }
    .default_button_wrapper{
        width: 100%;
    }
    .no_items{
        margin-left: 24px;
        padding-bottom: 24px;
        opacity: 0.7;
        font-size: 12px;
    }

}

