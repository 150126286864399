.all_info {
  background: #fafafa;
  display: flex;
  height: 100%;
  align-items: flex-start;
  padding: 0 32px 0;

  &__btn-group {
    display: flex;
    gap: 12px;
    margin-top: 16px;
  }

  &__outline-btn {
    padding: 6px 8px;
    max-width: 126px;
    font-size: 13px;
    line-height: 18px;
    color: #4a5568;
    border: 1px solid rgba(74, 85, 104, 0.9);
    border-radius: 8px;
    transition: color 0.3s, border-color 0.3s;

    &:hover,
    &:focus-visible {
      color: #1cbb32;
      border-color: #1cbb32;
    }
  }

  .all_info_block {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 32px 0 16px;
    margin: 0;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: space-between;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      -webkit-border-radius: 2px;
      background: #fafafa;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 5px;
      border-radius: 5px;
      background: rgba(74, 85, 104, 0.25);
    }

    &::-webkit-scrollbar-thumb:window-inactive {
      background: rgba(74, 85, 104, 0.25);
    }
  }

  .specifications {
    display: flex;
    flex-flow: column;

    .title_block {
      height: 26px;
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      text-transform: capitalize;
      color: #1a202c;
      padding-bottom: 18px;
    }

    .info_wrapper {
      display: flex;
      flex-flow: column;

      .add_info {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #ff010b;

        img {
          margin-left: 12px;
        }
      }
    }

    .block {
      display: flex;
      align-items: flex-start;
      padding-bottom: 14px;

      span {
        width: 170px;
        min-width: 170px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #1a202c;
        padding-right: 30px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        color: #4a5568;
      }
    }
  }

  .icon_product_wrapper {
    margin-right: 48px;
    display: flex;

    .big_icon {
      background-size: 100% 100%;
      width: 322px;
      height: 322px;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    .mini_icon {
      display: flex;
      flex-flow: column;
      margin-right: 22px;
      overflow-y: auto;

      > div {
        margin-bottom: 14px;
        border: 1.5px solid transparent;
        box-sizing: border-box;
        border-radius: 8px;
        background: #ffffff;
        width: 70px;
        height: 70px;

        img {
          width: 66px;
          height: 66px;
          border-radius: 8px;
          object-fit: cover;
        }
      }

      > div:last-of-type {
        margin-bottom: 0;
      }

      .active {
        border: 1.5px solid #cbd5e0;
      }
    }
  }

  .info_wrapper {
    min-width: 300px;
    display: flex;
    flex-flow: column;
    margin-right: 60px;

    .info_yes {
      min-height: 41px;
      background: rgba(28, 187, 50, 0.08);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #1cbb32;
      width: 154px;
      height: 41px;

      img {
        margin-right: 8px;
      }
    }

    .info_no {
      min-height: 41px;
      background: rgba(255, 1, 11, 0.08);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #ff010b;
      width: 154px;
      height: 41px;

      img {
        margin-right: 8px;
      }
    }

    .price {
      padding: 16px 0 12px;
      display: flex;
      flex-flow: column;

      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-transform: uppercase;
        color: #4a5568;
        display: flex;
        align-items: center;
        padding-bottom: 4px;
        & > div {
          margin-left: 11px;
          div {
            text-transform: initial;
            font-size: 12px;
            padding: 5px 8px;
            line-height: 14px;
            height: auto;
            min-height: auto;
          }
        }
      }

      p {
        color: #1a202c;
        font-weight: bold;
        font-size: 28px;
        line-height: 33px;
      }
    }

    .discount_price {
      p {
        color: #ff010b;
      }
    }

    .price_client {
      display: flex;

      > div:first-of-type {
        margin-right: 28px;
      }

      > div {
        display: flex;
        flex-flow: column;

        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          text-transform: uppercase;
          color: #4a5568;
          display: flex;
          padding-bottom: 4px;
        }

        p {
          font-weight: bold;
          font-size: 20px;
          line-height: 23px;
          color: #1a202c;
        }
      }

      & + .availability {
        margin-top: 20px;
      }
    }

    .availability {
      padding-top: 28px;
      display: flex;
      flex-flow: column;

      .descriptions {
        display: flex;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #1e282c;
        padding-bottom: 16px;
      }

      .block {
        display: flex;
        padding-bottom: 12px;

        span {
          min-width: 150px;
          font-weight: normal;
          font-size: 13px;
          line-height: 15px;
          color: #1e282c;
        }

        p {
          font-weight: normal;
          font-size: 13px;
          line-height: 15px;
          color: #2d3d45;
          padding-left: 12px;
        }
      }
      &_table {
        tr {
          th,
          td {
            font-size: 12px;
            line-height: 14px;
            padding: 8px;
            &:not(:first-child) {
              text-align: center;
            }
            &:first-child {
              padding-left: 0;
              span {
                font-weight: 500;
                margin-left: 4px;
              }
            }
            &:nth-child(2) {
              background-color: #e2e9ff;
            }
            &:nth-child(3) {
              background-color: #f1f7ff;
            }
          }
          &:first-child {
            th,
            td {
              font-weight: 500;
            }
          }
          &:not(:last-child) {
            border-bottom: 1px solid rgba(0, 0, 0, 0.08);
          }
        }
      }
    }
  }
}

.add_block {
  display: flex;
  margin-bottom: 20px;

  .input_add {
    display: flex;
    align-items: center;

    input {
      box-sizing: border-box;
      margin: 0 11px;
      height: 32px;
      padding: 0 4px;
      font-family: "RobotoMedium", sans-serif;
      font-size: 14px;
      text-align: center;
      border: 1.5px solid rgba(48, 49, 51, 0.15);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
      transition: 0.3s;
      width: 50px;
      border-radius: 4px !important;

      &:focus {
        border-color: rgba(48, 49, 51, 0.3);
      }
    }

    .cart-minus,
    .cart-plus {
      cursor: pointer;
      width: 12px;
    }

    .cart-minus {
      padding: 15px 0;
    }

    .cart-plus {
      padding: 10px 0;
    }
  }

  .cart-add-wrapper,
  .cart-added-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 30px;
    padding: 0 15px;
    height: 38px;
    background: #f3f5f6;
    box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;

    img:not(.added_img) {
      margin-right: 12px;
    }

    &.cart-add-wrapper {
      background: #1cbb32;
      cursor: pointer;
    }

    &.cart-no-items-add {
      background: #ff010b;
    }

    &.disabled {
      opacity: 0.3;
    }
  }
}

.dialog_product {
  padding: 60px 0;
  display: flex;
  justify-content: center;
  flex-flow: column;
  width: 100%;
  align-items: center;

  > div {
    font-weight: 500;
    font-size: 28px;
    text-align: center;
  }

  > img {
    margin-bottom: 32px;
  }

  &.lightbox {
    padding: 70px 0 0;

    .pinch-zoom-container {
      min-height: 300px;
      width: 460px;

      img {
        transform-origin: -35% 0% !important;
      }
    }
  }
}

.dialog_supplier {
  padding: 23px 28px 20px;
  display: flex;
  flex-flow: column;
  width: 100%;

  .storage_error {
    color: red;
    margin-bottom: 12px;
    font-size: 12px;
  }

  .title {
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 24px;
    display: flex;
  }

  .descriptions {
    font-size: 14px;
    line-height: 16px;
    color: #4a5568;
    margin-bottom: 12px;
    display: flex;
  }

  textarea {
    resize: none;
    background: #ffffff;
    border: 1px solid #cbd5e0;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    height: 120px;
    padding: 16px;
    margin-bottom: 24px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
  }

  .btn_dialog {
    display: flex;
    justify-content: space-between;

    .default_button_wrapper {
      width: calc(50% - 8px);

      button {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        height: 40px;
      }
    }
  }
}

@media only screen and (max-width: 1279px) {
  .dialog_product {
    padding: 40px 0;

    > div {
      font-size: 25px;
    }

    > img {
      margin-bottom: 25px;
    }
  }
  .all_info {
    height: auto;

    .all_info_block {
      flex-wrap: wrap;
      height: auto;

      .icon_product_wrapper {
        width: calc(60% - 20px);
        margin-right: 20px;

        .mini_icon {
          margin-right: 15px;
          max-height: 280px;

          > div {
            width: 60px;
            height: 60px;

            img {
              width: 56px;
              height: 56px;
            }
          }
        }

        .big_icon {
          width: 280px;
          height: 280px;
        }
      }

      .info_wrapper {
        min-width: auto;
        width: 40%;
        margin-right: 0;

        .availability {
          padding-top: 0;

          .btn_green {
            width: 100%;
          }
        }
      }

      .specifications {
        width: 100%;
        padding-top: 25px;

        .info_wrapper {
          width: 100%;

          .block {
            span {
              font-size: 15px;
              line-height: 20px;
              padding-right: 25px;
              width: 240px;
              min-width: 240px;
            }

            p {
              font-size: 15px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}

.special_discount {
  border-top: 1px dashed rgba(74, 85, 104, 0.5);
  border-bottom: 1px dashed rgba(74, 85, 104, 0.5);
  margin-bottom: 15px;
  padding: 10px 0 0;
  overflow-y: hidden;

  .MuiFormControlLabel-root {
    margin: 0;

    & > span {
      &:first-child {
        padding: 0;
        margin-right: 10px;
      }
    }
  }

  .inner {
    height: 0;
    margin-top: 10px;
    transition: height 150ms, margin 150ms;
    position: relative;

    .error {
      display: none;
    }

    &.open {
      height: 55px;
      margin-bottom: 20px;

      .error {
        display: inline-block;
        position: absolute;
        top: 100%;
        color: red;
        left: 0%;
        width: 100%;
        font-size: 12px;
      }
    }

    .line {
      display: flex;
      align-items: center;

      input {
        box-sizing: border-box;
        margin: 0 11px;
        height: 32px;
        padding: 0 4px;
        font-family: "RobotoMedium", sans-serif;
        font-size: 14px;
        text-align: center;
        border: 1.5px solid rgba(48, 49, 51, 0.15);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
        transition: 0.3s;
        width: 32px;
        border-radius: 4px !important;
      }
    }
  }
}
