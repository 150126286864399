.filter_wrap {
  position: relative;
  display: inline-flex;
  margin-right: auto;

  &__btn {
    display: flex;
    align-items: center;
    gap: 8px;
    min-height: 27px;
    font-size: 14px;
    line-height: 24px;
    color: #4a5568;

    &:hover {
      opacity: .7;
    }

    &.popover_btn {
      padding: 12px 12px 14px;
      width: 100%;
      border-bottom: 1px solid #e1ecf1;
    }
  }

  &__count {
    display: grid;
    place-items: center;
    min-width: 24px;
    height: 24px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    color: rgba(255, 1, 11, 0.95);
    border: 1.5px solid #ff010b;
    border-radius: 50%;
  }

  &__clear_btn {
    padding: 6px 16px;
    font-size: 13px;
    line-height: 18px;
    color: #4a5568;
    transition: opacity .3s;

    span {
      border-bottom: 1px dashed #4a5568;
    }

    &:hover {
      opacity: .7;
    }
  }

  &__popover {
    position: absolute;
    top: -4px;
    left: 0;
    z-index: 1;
    min-width: 219px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 16px rgba(48, 49, 51, 0.12);
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 14px;
  }

  &__item {
    input {
      position: absolute;
      z-index: -1;
      opacity: 0;

      &:checked + label:before {
        background-image: url('icons/checkbox.svg');
        background-color: #1cbb32;
        border-color: #1cbb32;
      }
    }

    label {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      line-height: 20px;
      color: #4a5568;
      cursor: pointer;
      transition: opacity .3s;

      &:hover {
        opacity: .7;
      }

      &:before {
        content: '';
        box-sizing: border-box;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-size: contain;
        border: 1px solid #cbd5e0;
        border-radius: 4px;
      }
    }
  }
}
