.custom_input_wrapper {
  width: 100%;
  height: 35px;
  margin-bottom: 36px!important;
  &:hover{
    .custom_input_outline{
      border-color: #1C2225;
    }
  }
  .custom_input_label {
    font-family: "Roboto", sans-serif;
    color: #4A5568;
    font-size: 16px;
    transform: translate(0px, 10px) scale(1);
    transition: all 0.1s linear;
    &.custom_input_label_focused {
      color: #4A5568;
      opacity: 1;
      font-family: "Roboto", sans-serif;
    }
    &.custom_input_label_active {
      font-family: "Roboto", sans-serif;
      color: #4A5568;
      opacity: 1;
      font-size: 14px;
      transform: translate(0px, -14px) ;
    }
    &.MuiFormLabel-filled {
      & + .phone_number {
        padding-left: 45px;
        &:before {
          opacity: .7;
        }
      }
    }
  }
  .custom_input {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    background-color: #FFFFFF;
    color: #373F51;
    font-size: 16px;
    border-radius: 0px;
    &:focus {
      background-color: #FFFFFF;
    }
    input {
      height: 35px;
      padding: 0px ;
      border-radius: 0;
      color: #1E282C;
      font-family: "Roboto",sans-serif;
      font-weight: 500;
      font-size: 16px;
    }
    .custom_input_outline {
      border: none;
      border-bottom: 1px solid #CFD8DC;
      legend {
        width: 0!important;
      }
    }
    &.custom_input_active{
      border-color: red!important;
    }
    &.custom_input_focused {
      background-color: #FFFFFF;
      .custom_input_outline {
        border: none;
        border-bottom: 1px solid #1C2225;
      }
    }
    &.custom_input_error, &.custom_input_error:hover {
      .custom_input_outline {
        border-color: #FF1919!important;
      }
    }
    &.phone_number {
      position: relative;
      &:before {
        content: '+380';
        position: absolute;
        display: block;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        transition: opacity 250ms;
      }
      &.custom_input_focused {
        padding-left: 45px;
        &:before {
          opacity: .7;
        }
      }
    }
  }
}

.error_text{
  color: #FF1919;
  position: absolute;
  top: 100%;
  left: 0;
  font-family: "Roboto",sans-serif;
  font-size: 10px;
  line-height: 13px;
  display: block;
}

@media only screen and (max-width: 1279px) {
  .custom_input_wrapper{
    z-index: 0;
    height: 30px;
    margin-bottom: 30px!important;
    .custom_input{
      input{
        height: 30px;
      }
    }
    .custom_input_label{
      font-size: 15px;
      &.custom_input_label_active{
        transform: translate(0px, -10px);
        font-size: 13px;
      }
    }

  }
}